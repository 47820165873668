.banner {
    min-height: 400px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.banner .mainHead {
    margin-bottom: 32px;
}
.banner .bannerCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
}
.banner .bannerCircle > div {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}