
* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
    sans-serif;
  font-size: 16px;
}

#root {
    display: block;
}
h1, h2, h3, h4, h5, p {
  margin-top: 0;
  margin-bottom: 0;
}
h1 {
  font-size: 5em;
}
h2 {
  font-size: 4em;
}
h3 {
  font-size: 3em;
}
h4 {
  font-size: 2em;
}
.body-1 {
  font-size: 1em;
}
.body-2 {
  font-size: .875em;
}
.body-big {
  font-size: 2em;
  font-weight: 400;
  letter-spacing: 8px;
}
.text-center {
  text-align: center;
}
.color-1-content {
  color: #fff000;
}
.color-1-bg {
  background-color: #fff000;
}
.color-2-content {
  color: #000fff;
}
.color-2-bg {
  background-color: #000fff;
}