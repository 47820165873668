.splash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background-color: #000;
}
.splashText {
    position: absolute;
    display: block;
    max-width: 95%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}